import config from "../config/rates.json";
import {getRatesForYear, Rates} from "./Rates";

export interface TaxOptions {
    desiredNetIncome: number;
    isChurchTax: boolean;
    isFreelancer: boolean;
    state: string;
    community: string;
    year: string;
}

export interface TaxInformation {
    taxOptions: TaxOptions;
    neededGrossIncome: number;
    netIncome: number;
    incomeTax: number;
    solidaritySupplement: number;
    churchTax: number;
    commercialTax: number;
}

const rates = config as Rates;

const maxEstimations = 100;

export function estimateTaxInformation(taxOptions: TaxOptions): TaxInformation {
    let grossIncome = estimateFittingGrossIncome(taxOptions);

    for (let i = 1; ; i++) {
        const calculatedTaxInformation = calculateTaxInformation(grossIncome, taxOptions);

        if (calculatedTaxInformation.netIncome === taxOptions.desiredNetIncome || i >= maxEstimations) {

            return calculatedTaxInformation;
        }

        let deltaFactor = taxOptions.desiredNetIncome / calculatedTaxInformation.netIncome;

        grossIncome = Math.floor(grossIncome * deltaFactor);
    }
}

export function calculateTaxInformation(grossIncome: number, taxOptions: TaxOptions): TaxInformation {
    const incomeTax = Math.round(calculateIncomeTax(grossIncome, taxOptions));
    const churchTax = taxOptions.isChurchTax ? Math.round(calculateChurchTax(incomeTax)) : 0;
    const commercialTax = taxOptions.isFreelancer ? 0 : Math.round(calculateCommercialTax(grossIncome, taxOptions));
    const solidaritySupplement = Math.round(calculateSolidaritySupplement(incomeTax, grossIncome));

    const netIncome = Math.round(grossIncome - incomeTax - churchTax - commercialTax - solidaritySupplement);

    return {
        taxOptions: taxOptions,
        neededGrossIncome: grossIncome,
        netIncome: netIncome,
        churchTax: churchTax,
        commercialTax: commercialTax,
        incomeTax: incomeTax,
        solidaritySupplement: solidaritySupplement,
    };
}

export function estimateFittingGrossIncome(taxOptions: TaxOptions): number {
    let rates = getRatesForYear(taxOptions.year);
    let factors = taxOptions.isChurchTax ? rates.estimation.withChurchTax : rates.estimation.withoutChurchTax;

    let estimatedFactor = 1;

    for (let factor of factors) {
        if (taxOptions.desiredNetIncome >= factor.minValue && factor.factor > estimatedFactor) {
            estimatedFactor = factor.factor
        }
    }

    return Math.floor(taxOptions.desiredNetIncome * estimatedFactor);
}

export function calculateIncomeTax(grossIncome: number, taxOptions: TaxOptions): number {
    let rates = getRatesForYear(taxOptions.year);
    if (grossIncome < rates.boundaries[0]) {
        return 0
    }

    // TODO configure boundary values
    if (grossIncome < rates.boundaries[1]) {
        return Math.floor((rates.parameters[0] * (grossIncome - (rates.boundaries[0]-1)) / 10000 + 1400) * (grossIncome - (rates.boundaries[0]-1)) / 10000);
    }


    if (grossIncome < rates.boundaries[2]) {
        return Math.floor((rates.parameters[1] * (grossIncome - rates.parameters[2]) / 10000 + 2397) * (grossIncome - rates.parameters[2]) / 10000 + rates.parameters[3]);
    }

    if (grossIncome < rates.boundaries[3]) {
        return Math.floor(0.42 * grossIncome - rates.parameters[4]);
    }

    return Math.floor(0.45 * grossIncome - rates.parameters[5]);
}

export function calculateChurchTax(incomeTax: number): number {
    return incomeTax * rates.constant.church;
}

export function calculateCommercialTax(grossIncome: number, taxOptions: TaxOptions): number {
    if (grossIncome < 24600) {
        return 0;
    }

    let commercialTaxRate = getCommercialRate(taxOptions);

    return Math.floor((grossIncome - 24500) * 0.035) * commercialTaxRate / 100;
}

function getCommercialRate(taxOptions: TaxOptions): number {
    let rates = getRatesForYear(taxOptions.year)

    return rates.commercial.filter(e => e.state === taxOptions.state && e.community === taxOptions.community)[0].rate;
}

export function calculateSolidaritySupplement(incomeTax: number, grossIncome: number): number {
    if (grossIncome < 62128) {
        return 0;
    }

    if (grossIncome < 100000) {
        return (incomeTax - 16956) * 0.119;
    }

    return incomeTax * 0.055;
}
