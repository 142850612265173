import config from "../config/rates.json";

export interface Rates {
    constant: {
        church: number;
    };
    changing:
        YearlyRates[];
}

export interface YearlyRates {
    year: string;
    boundaries: number[];
    parameters: number[];
    estimation: {
        "withoutChurchTax": {
            "minValue": number;
            "factor": number;
        }[];
        "withChurchTax": {
            "minValue": number;
            "factor": number;
        }[];
    };
    commercial:
        {
            state: string;
            community: string;
            rate: number;
        }[];
}

export function getStates(year: string): string[] {
    const commercialRates = getRatesForYear(year).commercial;

    let result: Set<string> = new Set([]);
    for (let commercialRate of commercialRates) {
        result.add(commercialRate.state);
    }

    return Array.from<string>(result);
}

export function getCommunitiesByState(year: string, state: string): string[] {
    const commercialRates = getRatesForYear(year).commercial;

    let result: Set<string> = new Set([]);
    for (let commercialRate of commercialRates) {
        if (commercialRate.state === state) {
            result.add(commercialRate.community);
        }
    }

    return Array.from<string>(result);
}

export function getRatesForYear(year: string): YearlyRates {
    return config.changing.filter(e => e.year === year)[0];
}

export function getCommunityString(community: string, state: string) {
    return community + ", " + state;
}

export function getYears(): string[]{
    return config.changing.map(yearConfig => yearConfig.year);
}
