import React, {Dispatch, useState} from 'react';
import './TaxCalculator.module.css';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectIsSubmitted, selectTaxInformation, submitTaxOptions} from "./TaxCalculatorSlice";
import styles from './TaxCalculator.module.css';
import {formatMoney} from "./utils/Formatting";
import {TaxInformation, TaxOptions} from "./utils/Calculation";

import FormControl from '@material-ui/core/FormControl';
import {
    Button,
    Container,
    Input,
    InputAdornment,
    InputLabel,
    MuiThemeProvider,
    TableFooter,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HelpIcon from '@material-ui/icons/Help';

import translations from "./config/translations.json";

import {createMuiTheme} from '@material-ui/core/styles';
import {getCommunitiesByState, getStates, getYears} from "./utils/Rates";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
    typography: {
        fontFamily: "'Montserrat', sans-serif",
        body1: {
            fontFamily: "'Montserrat', sans-serif"
        },
        h4: {
            fontFamily: "'Permanent Marker', handwriting"
        },
    },
});

const AccordionSummary = withStyles({
    expandIcon: {
        transform: 'none !important',
        color: "black"
    },
    expanded: {
        color: "grey"
    }
})(MuiAccordionSummary);


export function TaxCalculator(): JSX.Element {
    const taxInformation = useAppSelector(selectTaxInformation);
    const isSubmitted = useAppSelector(selectIsSubmitted);

    const translation = getTranslation();

    return <MuiThemeProvider theme={theme}>
        <Container maxWidth="sm" style={{paddingLeft: 35}}>
            {Header(translation)}
            {InputForm(translation)}
            {OutputTable(translation, taxInformation, isSubmitted)}
            {Footer(translation)}
        </Container>
    </MuiThemeProvider>;
}

function getTranslation(): any {
    const urlParams = new URLSearchParams(window.location.search);
    let language = urlParams.get('lang');

    if (language === null || !translations.hasOwnProperty(language)) {
        language = "fr";
    }

    return (translations as any)[language];
}

function Header(translation: any): JSX.Element {
    return <div>
        <Typography variant="h4" style={{marginTop: 20, textAlign: "center"}}>
            {translation.header.title}
        </Typography>
        <Typography variant="body1" style={{marginTop: 20, textAlign: "center"}}>
            {lineBreaksToParagraphs(translation.header.description)}
        </Typography>
    </div>;
}

function InputForm(translation: any) {
    const dispatch = useAppDispatch();

    const [desiredNetIncome, setDesiredNetIncome] = useState('');
    const [freelancer, setFreelancer] = useState('');
    const [churchTax, setChurchTax] = useState('');
    const [state, setState] = useState('');
    const [community, setCommunity] = useState('');
    const [year, setYear] = useState('');
    const [displayError, setDisplayError] = useState(false);

    const isInValid = desiredNetIncome === "" ||
        churchTax === "" ||
        year === "" ||
        community === "";

    return <div>
        <Typography style={{marginTop: 80, marginBottom: 20, textAlign: "center"}} variant="h4">
            {translation.input.title}
        </Typography>
        {
            ExplainedSelector(
                year,
                setYear,
                displayError,
                translation.input.fields.year.title,
                translation.input.fields.year.help,
                getYears()
            )
        }
        {
            ExplainedSelector(
                state,
                value => {
                    setCommunity("")
                    setState(value)
                },
                displayError,
                translation.input.fields.state.title,
                translation.input.fields.state.help,
                getStates("2022") // TODO Use selected year
            )
        }
        {
            ExplainedSelector(
                community,
                setCommunity,
                displayError,
                translation.input.fields.community.title,
                translation.input.fields.community.help,
                getCommunitiesByState("2022", state) // TODO Use selected year
            )
        }
        {
            ExplainedSelector(
                churchTax,
                setChurchTax,
                displayError,
                translation.input.fields.churchTax.title,
                translation.input.fields.churchTax.help,
                Object.values(translation.input.fields.churchTax.options)
            )
        }
        {
            ExplainedSelector(
                freelancer,
                setFreelancer,
                displayError,
                translation.input.fields.freelancer.title,
                translation.input.fields.freelancer.help,
                Object.values(translation.input.fields.freelancer.options)
            )
        }
        {
            DesiredNetIncomeField(
                desiredNetIncome,
                setDesiredNetIncome,
                displayError,
                translation.input.fields.desiredNetIncome.title,
                translation.input.fields.desiredNetIncome.help
            )
        }

        <Typography style={{visibility: isInValid && displayError ? "visible" : "hidden"}}
                    color={"error"}>{translation.input.error}</Typography>

        <div className={styles.buttonContainer}>
            <Button style={{fontFamily: "'Permanent Marker' cursive"}} variant="contained" color="primary" onClick={(e) => {
                e.preventDefault();

                if (isInValid) {
                    setDisplayError(true);
                    return;
                }

                setDisplayError(false);

                const taxOptions: TaxOptions = {
                    desiredNetIncome: Number(desiredNetIncome) || 0,
                    isChurchTax: churchTax === "Ja" || churchTax === "Oui", // TODO localised booleans
                    isFreelancer: freelancer === "Ja" || freelancer === "Oui",
                    state: state,
                    community: community,
                    year: year
                };
                dispatch(submitTaxOptions(taxOptions));
            }}>
                {translation.input.submit}
            </Button>
        </div>
    </div>;
}

function DesiredNetIncomeField(desiredNetIncome: string, setDesiredNetIncome: Dispatch<string>, displayError: boolean, title: string, help: string): JSX.Element {
    const field =
        <FormControl style={{width: "100%", marginTop: 6}} required
                     error={desiredNetIncome === "" && displayError}>
            <InputLabel htmlFor="desired-net-income">{title}</InputLabel>
            <Input
                id="desired-net-income" value={desiredNetIncome}
                onChange={(e) => setDesiredNetIncome(e.target.value)}
                endAdornment={<InputAdornment position="end">€</InputAdornment>}
            />
        </FormControl>;

    return AddExplanation(field, help);
}

function ExplainedSelector(value: string, setValue: Dispatch<string>, displayError: boolean, title: string, help: string, options: string[]) {
    return AddExplanation(Selector(title, options, value, setValue, displayError), help);
}

function Selector(label: string, options: string[], value: string, setValue: Dispatch<string>, displayError: boolean) {
    const error = displayError && (value === "");

    return <Autocomplete
        options={options}
        style={
            {
                width: "100%",
                marginTop: 0,
            }
        }
        onChange={(e, v) => {
            const value = typeof v === "string" ? v : "";
            setValue(value);
        }}
        renderInput={(params) => <TextField error={error} required {...params} value={value}
                                            label={label}
                                            variant="outlined"/>}
    />
}

function AddExplanation(element: JSX.Element, explanation: string): JSX.Element {
    return <Accordion className={styles.ExplanationContainer}>
        <AccordionSummary
            expandIcon={<HelpIcon/>}
            aria-label="Expand"
            aria-controls="additional-actions3-content"
            id="additional-actions3-header"
        >
            <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={element}

                label=""
                style={{width: "100%", lineHeight: 1.5}}
            />
        </AccordionSummary>
        <AccordionDetails>
            <Typography color="textSecondary">
                {lineBreaksToParagraphs(explanation)}
            </Typography>
        </AccordionDetails>
    </Accordion>
}

function OutputTable(translation: any, taxInformation: TaxInformation, isSubmitted: boolean): JSX.Element {
    return <div style={{visibility: isSubmitted ? "visible" : "hidden", textAlign: "center", marginTop: 36}}>
        <Typography style={{marginBottom: 32}} variant="h4">
            {translation.output.title}
        </Typography>

        <TableContainer style={{marginTop: 25}} component={Paper}>
            <Table aria-label="caption resultTable">
                <caption>{translation.output.footer}</caption>
                <TableBody>
                    <TableRow key="netIncome">
                        <TableCell>{AddExplanation(
                            <span>{translation.output.fields.netIncome.title}</span>, translation.output.fields.netIncome.help)}</TableCell>
                        <TableCell
                            align="right">{formatMoney(taxInformation.netIncome)}</TableCell>
                    </TableRow>
                    <TableRow key="incomeTax">
                        <TableCell>{AddExplanation(
                            <span>{translation.output.fields.incomeTax.title}</span>, translation.output.fields.incomeTax.help)}</TableCell>
                        <TableCell align="right">{formatMoney(taxInformation.incomeTax)}</TableCell>
                    </TableRow>
                    <TableRow key="solidaritySupplement">
                        <TableCell>{AddExplanation(
                            <span>{translation.output.fields.solidaritySupplement.title}</span>, translation.output.fields.solidaritySupplement.help)}</TableCell>
                        <TableCell align="right">{formatMoney(taxInformation.solidaritySupplement)}</TableCell>
                    </TableRow>
                    <TableRow key="churchTax">
                        <TableCell>{AddExplanation(
                            <span>{translation.output.fields.churchTax.title}</span>, translation.output.fields.churchTax.help)}</TableCell>
                        <TableCell align="right">{formatMoney(taxInformation.churchTax)}</TableCell>
                    </TableRow>
                    <TableRow key="commercialTax">
                        <TableCell>{AddExplanation(
                            <span>{translation.output.fields.commercialTax.title}</span>, translation.output.fields.commercialTax.help)}</TableCell>
                        <TableCell align="right">{formatMoney(taxInformation.commercialTax)}</TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell className={styles.resultCell}>{AddExplanation(
                            <span>{translation.output.fields.neededGrossIncome.title}</span>, translation.output.fields.neededGrossIncome.help)}</TableCell>
                        <TableCell className={styles.resultCell}
                                   align="right"
                                   style={{width: "20%"}}>{formatMoney(taxInformation.neededGrossIncome)}</TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    </div>;
}

function Footer(translation: any): JSX.Element {
    return <Typography style={{marginTop: 36, textAlign: "center", marginBottom: 20}} variant="body1">
        {lineBreaksToParagraphs(translation.footer.surveyText)}
        <a target="_blank" rel="noopener noreferrer" href={translation.footer.survey}>{translation.footer.survey}</a>
        {lineBreaksToParagraphs(translation.footer.contact)}
        <a href={"mailto:" + translation.footer.email}>{translation.footer.email}</a>
    </Typography>;
}

function lineBreaksToParagraphs(input: string): JSX.Element[] {
    return input.split("\n").map((i, key) => {
        return <p key={key}>{i}</p>;
    });
}
