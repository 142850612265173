import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {estimateTaxInformation, TaxInformation, TaxOptions} from "./utils/Calculation";

export interface IncomeTaxState {
    taxInformation: TaxInformation;
    isSubmitted: boolean;
}

const initialState: IncomeTaxState = {
    taxInformation: {
        taxOptions: {
            desiredNetIncome: 0,
            isChurchTax: false,
            isFreelancer: false,
            state: "",
            community: "",
            year: ""
        },
        neededGrossIncome: 0,
        netIncome: 0,
        churchTax: 0,
        commercialTax: 0,
        incomeTax: 0,
        solidaritySupplement: 0,
    },
    isSubmitted: false,
};

export const taxCalculatorSlice = createSlice({
    name: 'taxCalculator',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        submitTaxOptions: (state: IncomeTaxState, action: PayloadAction<TaxOptions>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.taxInformation = estimateTaxInformation(action.payload);
            state.isSubmitted = true;
        },
    },
});

export const {submitTaxOptions} = taxCalculatorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.example.value)`
export const selectTaxInformation = (state: RootState) => state.taxCalculator.taxInformation;
export const selectIsSubmitted = (state: RootState) => state.taxCalculator.isSubmitted;

export default taxCalculatorSlice.reducer;
